/* Style the Navbar */

.logo-img{
    width: 50px;
    height: 50px;
    
}
.custom-navbar {
    transition: background-color 0.3s ease-in-out;
    /* Smooth transition on scroll */
}

.custom-navbar .brand {
    font-size: 1.5rem;
    color: white !important;
    font-weight: bold;
    text-align: left;
}

.custom-navbar .nav-link-custom {
    color: white !important;
    text-decoration: none;
    margin: 0 15px;
}

.custom-navbar .nav-link-custom:hover {
    color: #ddd !important;
}

.navbar-nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.search-button {
    background-color: transparent;
    border: none;
    color: white;
    margin-right: 10px;
}

.search-button:hover {
    color: #ddd;
}

/* Toggle Icon for Small Screens */
.navbar-toggler {
    border-color: white;
}

@media (max-width: 992px) {
    .navbar-nav {
        flex-direction: column;
        align-items: center;
    }

    .nav-link-custom {
        margin: 10px 0;
    }
}


.css-1oi09mf {
    padding: 5px 0 !important;
}

@media (max-width:576px) {
    .black-section {
        padding: 2px 5px !important;
    }

}