/* InspireSection.css */
.inspire-section p {
    color: white;
}

.inspire-section {
    height: 100vh;
    background-image: url('./images/2.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    /* Ensure no extra content spills */
}

.inspire-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Black overlay with 60% opacity */
    z-index: 1;
    /* Ensure the overlay stays above the background */
}

.inspire-section>* {
    position: relative;
    z-index: 2;
    /* Ensure content stays above the overlay */
    color: #fff;
    /* White text for readability */
}


.bg-dark-treanlent {
    /* position: absolute; */

    /* transform: translateX(-50%); */
    /* padding: theme.spacing(2); */
    /* color: "#fff"; */
    border-radius: 5px;
    padding: 20px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    /* // backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability */
    /* borderRadius: "8px", // Rounded corners */
    /* width: "80%", */
    /* padding: 10px; */
}

/* 
.inspire-content p{
    color: white;
} */
.inspire-content p,
h2 {
    color: white;
}

.inspire-content {
    color: white;
    text-align: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    background-color: red !important;
}

.inspire-content.visible {
    opacity: 1;
    transform: translateY(0);
}

.contact-button {
    padding: 10px 20px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    cursor: pointer;
    margin-top: 20px;
}

.contact-button:hover {
    background-color: white;
    color: black;
}